const {EventEmitter} = require("events");
const {lerp, getMousePos, calcWinsize, distance} = require("utils/utils");

class ButtonCtrl extends EventEmitter {
	constructor(el) {
		super();
		// DOM elements
		// el: main button
		this.DOM = {el: el};
		// amounts the button will translate
		this.renderedStyles = {
			tx: {previous: 0, current: 0, amt: 0.1},
			ty: {previous: 0, current: 0, amt: 0.1},
		};
		// button state (hover)
		this.state = {
			hover: false,
		};
		// calculate size/position
		this.calculateSizePosition();
		// init events
		this.initEvents();
		// loop fn
		requestAnimationFrame(() => this.render());

		this.mousepos = {x: 0, y: 0};
		window.addEventListener("mousemove", (ev) => (this.mousepos = getMousePos(ev)));

		this.winsize;
		window.addEventListener("resize", () => (this.winsize = calcWinsize()));
	}
	calculateSizePosition() {
		// size/position
		this.rect = this.DOM.el.getBoundingClientRect();
		// the movement will take place when the distance from the mouse to the center of the button is lower than this value
		this.distanceToTrigger = this.rect.width * 0.75;
	}
	initEvents() {
		this.onResize = () => this.calculateSizePosition();
		window.addEventListener("resize", this.onResize);
	}
	render() {
		// calculate the distance from the mouse to the center of the button
		const distanceMouseButton = distance(
			this.mousepos.x + window.scrollX,
			this.mousepos.y + window.scrollY,
			this.rect.left + this.rect.width / 2,
			this.rect.top + this.rect.height / 2
		);
		// new values for the translations
		let x = 0;
		let y = 0;

		if (distanceMouseButton < this.distanceToTrigger) {
			if (!this.state.hover) {
				this.enter();
			}
			x = (this.mousepos.x + window.scrollX - (this.rect.left + this.rect.width / 50)) * 0.3;
			y = (this.mousepos.y + window.scrollY - (this.rect.top + this.rect.height / 50)) * 0.3;
		} else if (this.state.hover) {
			this.leave();
		}

		this.renderedStyles["tx"].current = x;
		this.renderedStyles["ty"].current = y;

		for (const key in this.renderedStyles) {
			this.renderedStyles[key].previous = lerp(
				this.renderedStyles[key].previous,
				this.renderedStyles[key].current,
				this.renderedStyles[key].amt
			);
		}

		this.DOM.el.style.transform = `translate3d(${this.renderedStyles["tx"].previous}px, ${this.renderedStyles["ty"].previous}px, 0)`;

		requestAnimationFrame(() => this.render());
	}
	enter() {
		this.emit("enter");
		this.state.hover = true;
		this.DOM.el.classList.add("button--hover");
		document.body.classList.add("active");
	}
	leave() {
		this.emit("leave");
		this.state.hover = false;
		this.DOM.el.classList.remove("button--hover");
		document.body.classList.remove("active");
	}
	clean() {
		// this.mousepos = {x: 0, y: 0};
		// this.winsize;
		// this.DOM = null;
		// // amounts the button will translate
		// this.renderedStyles = {
		// 	tx: {previous: 0, current: 0, amt: 0.1},
		// 	ty: {previous: 0, current: 0, amt: 0.1},
		// };
		window.removeEventListener("resize", this.onResize);
		window.removeEventListener("resize", this.mousepos);
		window.removeEventListener("mousemove", this.winsize);
	}
}
module.exports = ButtonCtrl;
