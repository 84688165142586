import React, {useRef, useEffect} from "react";

// Styles
import {CButtonWrapper} from "./CButton.styles.js";

function CButton({text = "Confirm"}) {
	const buttonRef = useRef(null);

	useEffect(() => {
		let button = undefined;
		if (typeof window !== `undefined`) {
			const {MouseCursor} = module.require("theme/mouse-cursor-instance");
			const enter = () => {
				MouseCursor.enter();
			};
			const leave = () => {
				MouseCursor.leave();
			};
			const ButtonCtrl = module.require("theme/button-enter");
			setTimeout(() => {
				if (buttonRef !== null) {
					button = new ButtonCtrl(buttonRef.current);
					button.on("enter", enter);
					button.on("leave", leave);
				}
			}, 100);
			return () => {
				button.removeListener("enter", enter);
				button.removeListener("leave", leave);
			};
		}
	}, [buttonRef]);
	return (
		<CButtonWrapper ref={buttonRef} className="btn-enter">
			<span>{text}</span>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="25.624"
				height="17.089"
				viewBox="0 0 25.624 17.089"
				style={{marginLeft: 20}}
			>
				<path
					id="Icon_ionic-ios-arrow-round-forward"
					data-name="Icon ionic-ios-arrow-round-forward"
					d="M24.216,11.579a1.163,1.163,0,0,0-.009,1.638l5.411,5.42H9.023a1.157,1.157,0,0,0,0,2.314H29.609L24.2,26.371a1.171,1.171,0,0,0,.009,1.638A1.152,1.152,0,0,0,25.835,28l7.334-7.387h0a1.3,1.3,0,0,0,.24-.365A1.1,1.1,0,0,0,33.5,19.8a1.16,1.16,0,0,0-.329-.81l-7.334-7.387A1.134,1.134,0,0,0,24.216,11.579Z"
					transform="translate(-7.875 -11.252)"
				/>
			</svg>
		</CButtonWrapper>
	);
}

export default CButton;
