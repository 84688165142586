import styled from "styled-components";

export const ClientesWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;

	img {
		width: 30%;
		margin-right: 5%;
		&:nth-child(3) {
			margin-right: 0;
		}

		box-sizing: border-box;
	}

	.grey-bg {
		background-color: #999;
		padding: 3px;
	}
`;
