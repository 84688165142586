import React, {useState, useEffect} from "react";
import {useSpring, animated} from "react-spring";
import Clientes from "screens/Home/Clientes";
import Body from "components/layout/Body/Body";
import CButton from "components/units/CButton/CButton";
import SEO from "components/seo";

import ComingSoon from "screens/ComingSoon/ComingSoon";

const IndexPage = () => {
	const [isLoaded, setIsLoaded] = useState(false);
	useEffect(() => {
		const loaded = setTimeout(() => {
			setIsLoaded(true);
		}, 500);
		return () => clearTimeout(loaded);
	}, []);
	const props = useSpring({opacity: isLoaded ? 1 : 0});

	return (
		<>
			<SEO
				title="Kevin Mamaqi Kapllani"
				description="Senior Javascript Developer. Working mostly with React, ReactNative, Node.js & Typescript, altogether with PostgreSQL, MongoDB and CI/CD pipelines to build medical applications."
			/>
			<ComingSoon />
		</>
	);
};
IndexPage.Layout = Body;
export default IndexPage;
