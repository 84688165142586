import React from "react";
import AmsterdamUMC from "assets/img/customers/amsterdamUMC.png";
import LUMC from "assets/img/customers/lumc.png";
import Onabitz from "assets/img/customers/onabitz.png";
import Teldat from "assets/img/customers/teldat.png";
import VRlife from "assets/img/customers/vrlife.png";

import {ClientesWrapper} from "./Clientes.styles.js";

function Clientes() {
	return (
		<ClientesWrapper>
			<img src={AmsterdamUMC} alt="Amsterdam UMC" />
			<img src={LUMC} alt="Leiden University Medical Centre" />
			<img src={Onabitz} alt="Onabitz" className="grey-bg" />
			<img src={Teldat} alt="Teldat" />
			<img src={VRlife} alt="VRLife" />
		</ClientesWrapper>
	);
}

export default Clientes;
