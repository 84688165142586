import styled from "styled-components";
import Colors from "theme/Colors";
import {shadeColor} from "utils/utils";

export const ComingSoonWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;

	a.malt {
		color: ${Colors.malt};
		border-bottom: 2px solid;
		transition: all 0.2s ease-in-out;

		&:hover {
			color: ${shadeColor(Colors.malt, -15)};
		}
	}

	a.linkedin {
		color: ${Colors.linkedin};
		border-bottom: 2px solid;
		transition: all 0.2s ease-in-out;

		&:hover {
			color: ${shadeColor(Colors.linkedin, -30)};
		}
	}

	.grey-bg {
		background-color: #999;
		padding: 3px;
	}
`;

export const SocialMenu = styled.ul`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	list-style: none;
	margin: 0;
	padding: 0;
	margin-bottom: 0px;

	li {
		display: inline-block;
	}

	svg {
		width: 42px;
		height: 42px;
		transition: all 0.2s ease;

		path {
			transition: all 0.2s ease;
		}

		&:hover {
			cursor: none;
		}

		&.malt {
			margin-right: 24px;
			&:hover {
				path {
					fill: #fc5656;
				}
			}
		}

		&.linkedin {
			&:hover {
				path#path14 {
					fill: #069;
				}
			}
		}
	}
`;
