import React from "react";
import {Link} from "gatsby";

import Logo from "assets/img/logo-192.png";
import Malt from "assets/img/Malt.svg";
import Linkedin from "assets/img/Linkedin.svg";

// Styles
import {ComingSoonWrapper, SocialMenu} from "./ComingSoon.styles";
import Colors from "theme/Colors";
function ComingSoon() {
	return (
		<ComingSoonWrapper>
			<Link to="/">
				<img
					src={Logo}
					alt="Kevin Mamaqi Kapllani"
					style={{maxWidth: 92, borderRadius: 46}}
				/>
			</Link>
			<h1 style={{color: "black", fontSize: 24, fontWeight: "normal"}}>
				Kevin Mamaqi Kapllani
			</h1>
			{/* <p>
				I'm a <strong>Senior Javascript Engineer</strong>, working as a FullStack Developer
				building medical applications. I mostly develop with React, ReactNative, Nodejs and
				Typescript, altogether with other technologies such as PostgreSQL, MySQL, CI/CD
				pipelines and more.
			</p> */}
			<p style={{marginBottom: 0}}>
				This page is under construction and will be online soon. In the meantime you can
				find me in{" "}
				<a
					className="malt"
					target="_blank"
					href="https://www.malt.es/profile/kevinmamaqikapllani"
				>
					Malt
				</a>{" "}
				and{" "}
				<a
					className="linkedin"
					target="_blank"
					href="https://www.linkedin.com/in/kevinmamaqi/"
				>
					LinkedIn
				</a>
				.
			</p>
			{/* <SocialMenu>
				<li>
					<a target="_blank" href="https://www.malt.es/profile/kevinmamaqikapllani">
						<Malt />
					</a>
				</li>
				<li>
					<a target="_blank" href="https://www.linkedin.com/in/kevinmamaqi/">
						<Linkedin />
					</a>
				</li>
			</SocialMenu> */}
		</ComingSoonWrapper>
	);
}

export default ComingSoon;
