import styled from "styled-components";

export const CButtonWrapper = styled.button`
	display: flex;
	align-items: center;
	padding: 12px 26px;
	font-size: 18px;
	background-color: white;
	color: black;
	border: 2px solid black;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, fill 0.15s ease-in-out;

	&.button--hover {
		color: white;
		background-color: black;

		svg {
			fill: white;
		}
	}
`;
